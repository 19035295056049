import { parseCookies, setCookie } from 'nookies'
import posthog from 'posthog-js'
import { useCallback, useEffect, useState } from 'react'
import { SearchPage } from 'src/constants/searchPages'
import { RedirectUrls } from 'src/types/types'
import ga from 'src/utils/pixels/gtag'
import { getHsp } from 'src/utils/pixels/hubspot'
import ManageCookiesModal, { IPreferences } from './ManageCookiesModal'

const COOKIE_SETTINGS = {
  maxAge: 365 * 24 * 60 * 60,
  path: '/'
}

interface CookieConsentProps {
  setCookiesDealt: (dealt: boolean) => void
}

const CookieConsentPopup: React.FC<CookieConsentProps> = ({
  setCookiesDealt
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [showManageModal, setShowManageModal] = useState(false)

  useEffect(() => {
    const path = window.location.pathname
    const pages = Object.values(SearchPage)
    if (pages.find((page) => path.includes(`/${page}`))) return

    const cookies = parseCookies()
    if (!cookies['cookie-consent'] && !cookies['cookie-consent-preferences']) {
      setIsVisible(true)
    }
  }, [])

  const initializeThirdParties = useCallback(() => {
    const cookies = parseCookies()
    const consent = cookies['cookie-consent']
    const pref = cookies['cookie-consent-preferences']
    if (!consent && !pref) return

    const preferences = pref ? JSON.parse(pref) : null
    const { analytics, advertisement, functionality } =
      consent === 'true'
        ? { analytics: true, advertisement: true, functionality: true }
        : preferences || {
            analytics: false,
            advertisement: false,
            functionality: false
          }

    const gtag = ga.getGtag()
    gtag('consent', 'update', {
      ad_storage: advertisement ? 'granted' : 'denied',
      ad_user_data: advertisement ? 'granted' : 'denied',
      ad_personalization: advertisement ? 'granted' : 'denied',
      analytics_storage: analytics ? 'granted' : 'denied',
      functionality_storage: functionality ? 'granted' : 'denied',
      personalization_storage: 'granted',
      security_storage: 'granted'
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any)

    posthog.set_config({
      persistence: analytics ? 'localStorage+cookie' : 'memory',
      disable_session_recording: !analytics
    })

    setCookiesDealt(true)
  }, [setCookiesDealt])

  useEffect(() => {
    initializeThirdParties()
  }, [initializeThirdParties])

  const handleAccept = () => {
    ga.event('cookie_consent', { action: 'accept' })
    setCookie(null, 'cookie-consent', 'true', COOKIE_SETTINGS)
    setIsVisible(false)
    initializeThirdParties()
  }

  const handleReject = () => {
    ga.event('cookie_consent', { action: 'reject' })
    setCookie(null, 'cookie-consent', 'false', COOKIE_SETTINGS)

    const _hsp = getHsp()
    _hsp.push(['revokeCookieConsent'])

    initializeThirdParties()
    setIsVisible(false)
    setCookiesDealt(true)
  }

  const onSavePreferences = (preferences: IPreferences) => {
    ga.event('cookie_consent', { action: 'save_preferences', ...preferences })
    const str = JSON.stringify(preferences)
    setCookie(null, 'cookie-consent-preferences', str, COOKIE_SETTINGS)

    initializeThirdParties()
    setShowManageModal(false)
    setIsVisible(false)
  }

  if (!isVisible) return null

  if (showManageModal) {
    return (
      <ManageCookiesModal
        onSave={onSavePreferences}
        onClose={() => setShowManageModal(false)}
      />
    )
  }

  return (
    <div className="fixed bottom-4 left-1/2 z-[1201] w-11/12 max-w-[500px] -translate-x-1/2 rounded-lg bg-white-500 p-6 shadow-lg transition-all duration-300 ease-in-out">
      <div className="flex items-start justify-center gap-4 md:mr-10">
        <p className="self-center text-xl font-bold text-black-500">
          Valoramos tu privacidad
        </p>
      </div>
      <div>
        <p className="mt-2 text-sm text-black-400">
          En este sitio usamos y recopilamos cookies para proporcionar un acceso
          seguro y ofrecer contenido adaptado a tus intereses. Al hacer clic en{' '}
          <strong>&quot;Aceptar&quot;</strong>, nos das permiso para su
          utilización. Para más información, ver nuestra{' '}
          <a
            href={RedirectUrls.Disclosures}
            className="ml-1 text-blue-600 underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Política de Privacidad.
          </a>
        </p>
      </div>

      <div className="mt-4 flex flex-col items-stretch gap-3 md:flex-row md:justify-end">
        <button
          onClick={handleAccept}
          className="rounded bg-blue-500 px-4 py-2 text-white-500 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
        >
          Aceptar cookies
        </button>
        <button
          onClick={() => setShowManageModal(true)}
          className="text-grey-700 rounded bg-grey-300 px-4 py-2 hover:bg-grey-400 focus:outline-none focus:ring focus:ring-grey-400"
        >
          Configurar cookies
        </button>
        <button
          onClick={handleReject}
          className="text-grey-700 hidden rounded bg-grey-300 px-4 py-2 hover:bg-grey-400 focus:outline-none focus:ring focus:ring-grey-400"
        >
          Rechazar cookies
        </button>
      </div>
    </div>
  )
}

export default CookieConsentPopup
