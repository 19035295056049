import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache'
import { ApolloClient, createHttpLink } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { RetryLink } from '@apollo/client/link/retry'
import { sha256 } from 'crypto-hash'
import firebase from 'firebase/compat/app'
import { isbot } from 'isbot'
import { GetServerSidePropsContext, PreviewData } from 'next'
import nookies, { parseCookies, setCookie } from 'nookies'
import config from './config'

// eslint-disable-next-line no-control-regex
const isoSafe = /[^\u0000-\u00ff]/g

export const BOT =
  'mozilla/5.0 (macintosh; intel mac os x 10_15_7) applewebkit/537.36 (khtml, like gecko) chrome/94.0.4590.2 safari/537.36 chrome-lighthouse'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FAKE_CTX = { req: { headers: { 'user-agent': BOT } } } as any

if (!config.isProd) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { loadErrorMessages, loadDevMessages } = require('@apollo/client/dev')
  loadDevMessages()
  loadErrorMessages()
}

async function setUserTokenCookie(user: firebase.User): Promise<void> {
  const token = await user.getIdToken()
  setCookie(null, 'token', token, {
    maxAge: 30 * 24 * 60 * 60,
    path: '/'
  })
}

async function removeTokenCookie(): Promise<void> {
  document.cookie =
    'token' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

function getCookies(context?: GetServerSidePropsContext) {
  return typeof window === 'undefined' ? nookies.get(context) : parseCookies()
}

const getApolloClient = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context?: GetServerSidePropsContext<any, PreviewData>
): ApolloClient<NormalizedCacheObject> => {
  const httpLink = createHttpLink({ uri: config.graphqlUrl })

  const authLink = setContext(async (_, { headers }) => {
    const cookies = getCookies(context)

    const token = context
      ? cookies.token
      : await firebase.auth().currentUser?.getIdToken()
    if (!context && token) {
      setCookie(null, 'token', token, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/'
      })
    }

    const utmParams = [
      'utm_page',
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content'
    ].reduce((acc, param) => {
      if (cookies[param] && typeof cookies[param] === 'string') {
        if (isoSafe.test(cookies[param])) {
          const sanitized = cookies[param].replace(isoSafe, '')
          cookies[param] = sanitized
        }
        return { ...acc, [param?.replace('_', '-')]: cookies[param] }
      }
      return acc
    }, {})

    const userAgent = context
      ? context.req.headers['user-agent']
      : typeof navigator !== 'undefined'
        ? navigator.userAgent
        : undefined
    const bot = isbot(userAgent)
    const clientSecret = context ? config.clientSecret : undefined

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        currency: cookies.currency,
        locale: cookies.locale,
        'session-id': cookies.session_id,
        'is-bot': bot.toString(),
        'client-secret': clientSecret,
        ...utmParams
      }
    }
  })

  const retryLink = new RetryLink()
  const linkChain = createPersistedQueryLink({ sha256 })
    .concat(authLink)
    .concat(retryLink)
    .concat(httpLink)

  const client = new ApolloClient({
    ssrMode:
      !!context ||
      typeof window === 'undefined' ||
      typeof document === 'undefined',
    link: linkChain,
    cache: new InMemoryCache()
  })

  return client
}

export { removeTokenCookie, setUserTokenCookie }
export default getApolloClient
